<template>
  <div class="event-marking-list">
    <PageTitle
      title="事件行銷列表"
      btn="新增"
      @btnClick="
        $router.push({
          name: 'EventMarketingEdit',
        })
      "
    />

    <FiltersContainer>
      <BaseElInput
        v-model="search.name"
        clearable
        placeholder="輸入事件名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElSelect
        v-model="search.status"
        clearable
        placeholder="選擇事件狀態"
        @clear="refresh(true)"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="status in eventStatusConfig"
          :key="status.value"
          :value="status.value"
          :label="status.label"
        />
      </BaseElSelect>
    </FiltersContainer>

    <EventMarkingTable
      v-loading="loading.table"
      :tableData="tableData"
      @refresh="refresh(false)"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, onActivated } from 'vue'
import { eventStatusConfig } from '@/config/marketing'
import EventMarkingTable from './components/EventMarkingTable.vue'
import { useTable } from '@/use/table'
import store from '@/store'
import {
  GetEventMarketingList,
  GetEventMarketingCount,
} from '@/api/eventMarketing'

export default defineComponent({
  name: 'EventMarkingList',
  components: { EventMarkingTable },
  setup () {
    const shopId = computed(() => store.getters.shop)
    const {
      tableData,
      tableDataCount,
      tableOptions,
      pageStartIndex,
      loading,
      fetchData,
      fetchDataCount,
    } = useTable()
    const search = reactive({
      name: null,
      status: null,
    })

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || null,
        status: search.status || null,
      }
      await Promise.allSettled([
        fetchData(GetEventMarketingList, payload),
        fetchDataCount(GetEventMarketingCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      search,
      refresh,
      eventStatusConfig,
      tableDataCount,
      tableOptions,
      tableData,
      loading,
    }
  },
})
</script>
